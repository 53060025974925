var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MbnTableTools',{attrs:{"search":_vm.search,"limit":_vm.limit,"is-include-campaign-id-filter":true,"campaign-id":_vm.campaignId},on:{"updateSearchQuery":function($event){_vm.search = $event},"updateLimit":_vm.updateLimit,"filterByCampaignId":_vm.filterByCampaignId,"resetSearch":_vm.resetSearch}}),_c('CRow',[_c('CCol',[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('sidebar.creatives')))]),_c('CButton',{attrs:{"size":"sm","color":"info"},on:{"click":_vm.createNewRecord}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" "+_vm._s(_vm.$t('ui.add_new_creative')))],1)],1),_c('CCardBody',[(_vm.error)?_c('MbnErrorMessage'):_c('CDataTable',{attrs:{"fields":_vm.fields,"items":_vm.tableItems,"striped":"","add-table-classes":"section-table","no-items-view":{ noItems: _vm.$t('ui.no_items_available') },"sorter":"","clickable-rows":"","loading":_vm.isLoading,"hover":""},on:{"row-clicked":_vm.edit},scopedSlots:_vm._u([{key:"active",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.active)}},[_vm._v(" "+_vm._s(item.active ? _vm.$t('ui.active') : _vm.$t('ui.inactive'))+" ")])],1)]}},{key:"moderated",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.moderated)}},[_vm._v(" "+_vm._s(item.moderated ? _vm.$t('ui.active') : _vm.$t('ui.inactive'))+" ")])],1)]}},{key:"approved",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.approved)}},[_vm._v(" "+_vm._s(item.approved ? _vm.$t('ui.active') : _vm.$t('ui.inactive'))+" ")])],1)]}},{key:"dateStart",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.normalizeDate(item.dateStart))+" ")])]}},{key:"dateEnd",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.normalizeDate(item.dateEnd))+" ")])]}},{key:"show_details",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[[(item.active)?_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.stop'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.deactivate(item)}}},[_c('CIcon',{attrs:{"name":"cil-media-pause"}})],1):_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.start'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.activate(item)}}},[_c('CIcon',{attrs:{"name":"cil-media-play"}})],1)],_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.edit'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.edit(item)}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1),_c('CButton',{attrs:{"title":_vm.$t('ui.delete'),"size":"sm","color":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.remove(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],2)])]}}])}),(_vm.isShowedPagination)?_c('CPagination',{attrs:{"pages":_vm.totalPages,"active-page":_vm.activePage,"size":"sm"},on:{"update:activePage":_vm.onChangePage}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }