<template>
  <div>
    <MbnTableTools
      :search="search"
      :limit="limit"
      :is-include-campaign-id-filter="true"
      :campaign-id="campaignId"
      @updateSearchQuery="search = $event"
      @updateLimit="updateLimit"
      @filterByCampaignId="filterByCampaignId"
      @resetSearch="resetSearch"
    />

    <CRow>
      <CCol>
        <CCard>
          <CCardHeader class="d-flex align-items-center justify-content-between">
            <span>{{ $t('sidebar.creatives') }}</span>
            <CButton size="sm" color="info" @click="createNewRecord"
              ><CIcon name="cil-plus" />&nbsp; {{ $t('ui.add_new_creative') }}</CButton
            >
          </CCardHeader>
          <CCardBody>
            <MbnErrorMessage v-if="error" />

            <CDataTable
              v-else
              :fields="fields"
              :items="tableItems"
              striped
              add-table-classes="section-table"
              :no-items-view="{ noItems: $t('ui.no_items_available') }"
              sorter
              clickable-rows
              :loading="isLoading"
              hover
              @row-clicked="edit"
            >
              <template #active="{ item }">
                <td>
                  <CBadge :color="getBadge(item.active)">
                    {{ item.active ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #moderated="{ item }">
                <td>
                  <CBadge :color="getBadge(item.moderated)">
                    {{ item.moderated ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #approved="{ item }">
                <td>
                  <CBadge :color="getBadge(item.approved)">
                    {{ item.approved ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #dateStart="{ item }">
                <td>
                  {{ normalizeDate(item.dateStart) }}
                </td>
              </template>
              <template #dateEnd="{ item }">
                <td>
                  {{ normalizeDate(item.dateEnd) }}
                </td>
              </template>
              <template #show_details="{ item }">
                <td>
                  <div class="d-flex">
                    <template>
                      <CButton
                        v-if="item.active"
                        :title="$t('ui.stop')"
                        size="sm"
                        class="mr-3"
                        color="info"
                        @click="deactivate(item)"
                      >
                        <CIcon name="cil-media-pause" />
                      </CButton>
                      <CButton
                        v-else
                        :title="$t('ui.start')"
                        size="sm"
                        class="mr-3"
                        color="info"
                        @click="activate(item)"
                      >
                        <CIcon name="cil-media-play" />
                      </CButton>
                    </template>
                    <CButton :title="$t('ui.edit')" size="sm" class="mr-3" color="info" @click="edit(item)">
                      <CIcon name="cil-pencil" />
                    </CButton>
                    <CButton :title="$t('ui.delete')" size="sm" color="danger" @click.prevent="remove(item)">
                      <CIcon name="cil-trash" />
                    </CButton>
                  </div>
                </td>
              </template>
            </CDataTable>

            <CPagination
              v-if="isShowedPagination"
              :pages="totalPages"
              :active-page="activePage"
              size="sm"
              @update:activePage="onChangePage"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_CREATIVES_LIST, GET_CAMPAIGN_CREATIVES_LIST, EDIT_CREATIVE } from '@/store/action-types/creatives';
import { limits } from '@/helpers/const';
import { getBadge } from '@/helpers/utils';
import { normalizeDate } from '@/helpers/normalize-date';
import searchByTable from '@/helpers/search-by-table';
import MbnTableTools from '@/components/TableTools';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'Creatives',
  components: {
    MbnTableTools,
    MbnErrorMessage,
  },
  data() {
    return {
      limit: this.$route.query.limit || limits[0].value,
      search: '',
      filtered: '',
      campaignId: '',
      activePage: Number(this.$route.query.page || '1'),
    };
  },
  computed: {
    ...mapState('creatives', ['error', 'isLoading', 'creativesList', 'total']),
    tableItems() {
      return this.filtered ? this.filtered : this.creativesList;
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    offset() {
      return this.activePage * this.limit - this.limit;
    },
    isShowedPagination() {
      return this.totalPages > 1 && !this.filtered;
    },
    fields() {
      return [
        { key: 'id', label: this.$t('creatives.id') },
        { key: 'campaignId', label: this.$t('creatives.campaign_id') },
        { key: 'userId', label: this.$t('creatives.user_id') },
        { key: 'name', label: this.$t('creatives.name') },
        { key: 'type', label: this.$t('creatives.type') },
        { key: 'clickUrl', label: this.$t('creatives.click_url') },
        { key: 'cpm', label: this.$t('creatives.cpm') },
        { key: 'linkType', label: this.$t('creatives.link_type') },
        { key: 'dateStart', label: this.$t('creatives.date_start') },
        { key: 'dateEnd', label: this.$t('creatives.date_end') },
        { key: 'approved', label: this.$t('creatives.approved') },
        { key: 'active', label: this.$t('creatives.active') },
        { key: 'show_details', label: this.$t('ui.actions'), sorter: false },
      ];
    },
  },
  watch: {
    search(value) {
      this.filterData(value);
    },
    activePage() {
      this.fetchCreativesList();
    },
    limit() {
      this.fetchCreativesList();
    },
  },
  mounted() {
    this.fetchAllCreatives();
  },
  methods: {
    ...mapActions('creatives', [GET_CREATIVES_LIST, GET_CAMPAIGN_CREATIVES_LIST, EDIT_CREATIVE]),
    resetSearch() {
      this.search = '';
    },
    updateLimit(value) {
      this.limit = value;
      this.activePage = 1;
      this.$router.push({ query: { limit: this.limit } });
    },
    createNewRecord() {
      this.$router.push({
        name: 'creative-create',
      });
    },
    filterData(query) {
      this.filtered = searchByTable(query, this.creativesList);
    },
    getBadge(status) {
      return getBadge(status);
    },
    normalizeDate(date) {
      return normalizeDate(date);
    },
    edit(item, index, column) {
      //do not remove index param!
      if (column === 'show_details') {
        return;
      }

      this.$router.push({
        name: 'creative-edit',
        params: { id: item.id },
      });
    },
    onChangePage(page) {
      this.activePage = page;
      this.$router.push({ query: { limit: this.limit, page } });
    },
    fetchAllCreatives() {
      this.GET_CREATIVES_LIST({
        limit: this.limit,
        offset: this.offset,
      });
    },
    fetchCampaignCreatives(campaignId) {
      this.GET_CAMPAIGN_CREATIVES_LIST({
        id: campaignId,
        params: {
          limit: this.limit,
          offset: this.offset,
        },
      });
    },
    fetchCreativesList() {
      if (this.campaignId) {
        this.fetchCampaignCreatives(this.campaignId);
      } else {
        this.fetchAllCreatives();
      }
    },
    filterByCampaignId(campaignId) {
      this.campaignId = campaignId ? campaignId.value : '';

      if (this.activePage > 1) {
        this.activePage = 1;
        this.$router.push({ name: 'creatives' });
      } else {
        this.fetchCreativesList();
      }
    },
    activate(record) {
      this.update(record, { field: 'active', value: true });
    },
    deactivate(record) {
      this.update(record, { field: 'active', value: false });
    },
    remove(record) {
      this.update(record, { field: 'deleted', value: true });
    },
    update(record, prop) {
      const creative = { ...record };
      creative[prop.field] = prop.value;
      this.EDIT_CREATIVE({ id: creative.id, formData: creative })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.fetchCreativesList();
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
